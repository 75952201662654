.faq-sec {
    margin: 30px 0;

    .faq-block {
        // background-color: $anti-flash-white;
        // border-radius: 16px;
        // padding: 28px 24px;

        .sub-heading {
            padding-top: 6px;
            letter-spacing: -0.005em;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        .hd26 {
            font-weight: 600;
            // color: $dark-grey;

            @include breakpoint($mxw768) {
                font-weight: 300;
                font-size: 24px;
                color: $c-black;
                margin-bottom: 18px;
            }

            @include breakpoint($mxw320) {
                font-size: 22px;
            }
        }

        @include breakpoint($mxw768) {
            padding: 23px 16px 30px;
        }

        .accordian {
            margin-top: 30px;
            // box-shadow: 0px 4px 8px rgba(37, 36, 59, 0.05);
            // border-radius: 8px;
            // overflow: hidden;

            .trgr {
                font-size: 16px;
                font-weight: 500;
                padding: 20px 40px 20px 24px;
                background: #F8F8F8;
                border-radius: 6px;
                border: 0px;
                margin-bottom: 10px;
                // border-color: $anti-flash-white;

                &:first-child {
                    //border-radius: 8px 8px 0 0;
                }

                &.last-trgr {
                    //border-radius: 0 0 8px 8px;
                }

                &.act {
                    background: #FAC013;
                    &.last-trgr {
                        //border-bottom-left-radius: 0;
                        //border-bottom-right-radius: 0;
                    }
                }

                &.act + .tog_cont:last-child {
                    //border-bottom-left-radius: 8px;
                    //border-bottom-right-radius: 8px;
                }

                &::before {
                    display: inline-block;
                    content: "";
                    width: 24px;
                    height: 24px;
                    background: url(../images/chevron-down.svg) no-repeat;
                    right: 20px;
                    top:25px;
                }

                &.act::before {
                    display: inline-block;
                    content: "";
                    width: 24px;
                    height: 24px;
                    background: url(../images/chevron-up.svg) no-repeat;
                }
            }

            .tog_cont {
                padding: 20px 24px;
                font-size: 14px;
                line-height: 24px;
                // border-bottom: 1px solid $anti-flash-white;
                border: 0px;
                .link-n {
                    background: none;
                    // color: $c-red1;

                    @include breakpoint($mxw768) {
                        font-size: 12px;
                    }
                }
            }

            @include breakpoint ($mxw768) {
                .trgr {
                    color: #402B2B;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 300;
                    padding: 21px 45px 21px 22px;

                    &::before {
                        right: 20px;
                    }

                    &:first-child {
                        border-radius: 4px 4px 0 0;
                    }

                    &.last-trgr {
                        border-radius: 0 0 4px 4px;
                    }

                    &.act + .tog_cont:last-child {
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }

                .tog_cont {
                    font-size: 14px;
                    line-height: 20px;
                    // color: $c-black-pure;
                    padding: 16px 24px;
                    font-weight: 400;
                }
            }

            @include breakpoint ($mxw768) {
                margin-top: 18px;
            }
        }

        @include breakpoint($mxw768) {
            border-radius: 0;
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    @include breakpoint($mxw768) {
        margin-top: 36px;
    }

    &.faq-new {
        .faq-block .accordian .trgr {
            font-weight: 600;

            @include breakpoint ($mxw768) {
                font-weight: 300;
            }
        }

        @include breakpoint ($mxw768) {
            margin-top: 0;
        }

        .faq-block .accordian {
            border-radius: 8px 8px 0 0;
            box-shadow: 0px 4px 8px rgba(37, 36, 59, 0.05);
            border-color: #D8D8D8;

            @include breakpoint ($mxw768) {
                border-radius: 8px 8px 0 0;
            }

            .trgr {
                box-shadow: 0px 4px 8px rgba(37, 36, 59, 0.05);
                border-radius: 4px;
                border-color: #D8D8D8;
            }
        }
    }
}