// $font-light: 'Gilroy-Light',
// serif;
// $font-bold: 'Gilroy-ExtraBold',
// serif;
//grid
$mxw320: max-width 360px;
$mxw375: max-width 375px;
$mxw768: max-width 767px;
$mxw769: max-width 768px;
$mnw769: min-width 768px;
$mxw1000: max-width 1024px;
$mxw992: max-width 991px;
$mnw992: min-width 992px;
$mxw1200: max-width 1199px;
$mxw1280: max-width 1280px;
//colors

$c-body:#525252;
$c-white: #fff;
$c-black: #1f211d;
$c-red: #d10101;
$c-green: #00ac7d;
$c-blue: #0811A3;
$c-blue2: #020A51;
$c-yellow: #FAC013;
$c-gray: #F7F7F7;
$c-gray2:#F0F0F0;
$c-gray3:#2a2a3e;
$c-gray4:#f8f8f8;
$c-gray5:#ebebeb;







