header {
  position: relative;
  z-index: 3;

  .top-nav {
    background: $c-white;
    padding-bottom: 34px;
    padding-top: 34px;

    @include breakpoint($mxw769) {
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .logo {
      img {
        width: 260px;

        @include breakpoint($mxw769) {
          width: 170px;
        }
      }
    }

    .align-items-center {
      display: flex;
      align-items: center;
    }
  }

  .navbar {
    .navbar-collapse {
      background: $c-gray3;
      border-radius: 6px;
      padding: 0 15px;
    }
  }

  .navbar > .container .navbar-brand {
    padding: 17px 0 0 0;

    @include breakpoint($mxw769) {
      padding-top: 10px;

      img {
        height: auto;
        width: 180px;
        max-width: inherit;
      }
    }
  }

  .main-nav {
    // background: rgba($c-white, 0.5);
    // background: #2a2a3f;
    padding: 0px;
    display: flex;
    align-items: center;
    // min-height: 90px;
    margin-bottom: 0px;
    border-radius: 0px;
    border: 0px;
    position: relative;
    transition: all 0.6s ease-in-out;

    @include breakpoint($mxw769) {
      display: none;
    }

    @include breakpoint($mxw768) {
      min-height: inherit;
      background: $c-white;
      // display: inherit;
      box-shadow: 0px 0px 10px -6px rgba($c-black, 0.6);

      .navbar-header {
        margin: 0px;
        padding-bottom: 10px;

        .navbar-toggle {
          margin-top: 15px;
          margin-bottom: 0px;
          border: 0px;
          border-radius: 0px;
          margin-right: 0px;

          .icon-bar {
            background: $c-black;
            width: 20px;
            height: 3px;
            border-radius: 0;
          }

          &.collapsed {
          }

          &:hover,
          &:focus {
            background: inherit;
          }
        }
      }
    }

    &.act {
      position: fixed;
      width: 100%;
      top: 0px;
      background: $c-gray3;
      // box-shadow: 0px 0px 7px 5px rgb(0 0 0 / 36%);
      box-shadow: 0px 0px 7px 5px rgba($c-black, 0.4);
      transition-property: height, top, background;
    }

    .nav.navbar-nav {
      // margin-top: 6px;

      @include breakpoint($mxw768) {
        margin: 0px;
        padding-top: 0;
      }

      li {
        padding: 14px 0;
        margin-right: 15px;
        @include breakpoint($mxw768) {
          padding: 7px 0;
          margin-right: 0px;
        }

        a {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          color: $c-white;
          // border-right: solid 1px $c-black;
          padding: 10px 15px;
          line-height: 1.1;
          line-height: 1;
          background: inherit;

          @include breakpoint($mxw1000) {
            padding: 0px 7px;
          }

          @include breakpoint($mxw769) {
            font-size: 10px;
          }

          @include breakpoint($mxw768) {
            border-right: 0px;
            font-size: 14px;
          }

          &:hover {
            color: $c-yellow;
          }
        }

        &.active {
          // border-bottom: solid 4px $c-green;
          background: transparent
            linear-gradient(90deg, #0448d7 0%, #068bf4 100%) 0% 0% no-repeat
            padding-box;
          border-radius: 6px;

          a {
            color: $c-white;
            background: transparent;
          }
        }

        &.impt {
          a {
            background: #83e2ff 0% 0% no-repeat padding-box;
            border-radius: 6px;
            color: #000b91;
          }
        }

        &:last-child {
          a {
            border-right: solid 0px $c-black;
            padding-right: 0px;
          }
        }

        &:nth-last-child(2) {
          a {
            border-right: solid 0px $c-black;
          }
        }

        &.dropdown {
          &.full-width {
            width: 100%;
          }

          &:hover {
            a {
              color: $c-green;
            }
          }

          .dropdown-menu.hover {
            top: 64px;
            min-width: 210px;
            border-top: 3px solid $c-green;
            padding-top: 10px;
            padding-bottom: 10px;

            @include breakpoint($mxw768) {
              position: inherit;
              top: 0;
              min-width: inherit;
              border-top: 0px;
              padding-top: 10px;
              padding-bottom: 10px;
              width: 100%;
              box-shadow: inherit;
            }

            &:before {
              content: "";
              width: 0px;
              height: 0px;
              border-left: 9px solid transparent;
              border-right: 9px solid transparent;
              border-bottom: 9px solid $c-green;
              top: -11px;
              right: 50px;
              position: absolute;

              @include breakpoint($mxw768) {
                display: none;
              }
            }

            li {
              padding: 0px;

              &:last-child {
                a {
                  border-bottom: 0px;
                }
              }

              a {
                border-bottom: solid 1px rgba($c-black, 0.1);
                color: $c-black;
                border-right: 0px;
                white-space: inherit;
                text-transform: inherit;
                padding: 10px 20px;

                @include breakpoint($mxw768) {
                  background: rgba($c-black, 0.1);
                }

                &:hover {
                  color: $c-green;
                  background: rgba($c-black, 0.1);
                }
              }
            }
          }
        }

        &.emerg-container {
          width: 150px;
          text-align: center;

          @include breakpoint($mxw1000) {
            width: 120px;
          }

          @include breakpoint($mxw769) {
            width: 100px;
          }

          @include breakpoint($mxw768) {
            width: 100%;
            text-align: left;
          }

          .emerg {
            position: absolute;
            width: 100%;
            background: $c-red;
            color: #fff;
            padding: 6px 20px;
            right: 0px;
            font-weight: 700;
            text-align: center;
            height: 90px;
            display: flex;
            font-size: 14px;
            flex-direction: column;
            justify-content: center;
            top: -6px;

            @include breakpoint($mxw1000) {
              padding: 0px 10px;
            }

            @include breakpoint($mxw769) {
              font-size: 10px;
            }

            @include breakpoint($mxw768) {
              font-size: 14px;
              padding: 10px 20px;
              height: inherit;
              text-align: left;
              position: inherit;
              display: inline-block;
              top: inherit;
              width: auto;

              > ul {
                display: inline-block;
                padding-left: 12px;
              }
            }

            span {
              display: block;

              @include breakpoint($mxw768) {
                display: inline-block;
                vertical-align: top;
              }
            }

            .dropdown {
              padding: 0px;

              .dropdown-toggle {
                padding-left: 0px;
                color: #fff;
              }
            }

            .dropdown-menu {
              top: 23px;

              li {
                padding: 0px;

                a {
                  color: $c-black;
                  background: #fff;
                  padding: 10px 15px;

                  &:hover {
                    background: rgba($c-black, 0.1);
                  }
                }
              }
            }

            a {
              color: $c-white;
            }
          }
        }
      }
    }
  }

  .navbar-nav {
    display: flex;
    flex-direction: row;
  }
}

@include breakpoint($mxw769) {
  .logo {
    border-bottom: solid 1px #eee;
    padding-bottom: 15px;
  }
}

.home-banner {
  position: relative;
  margin-top: -31px;

  @include breakpoint($mxw769) {
    margin-top: 0px;
  }

  &.act {
    margin-top: 0px;
  }

  .banner {
    img {
      width: 100%;
    }

    position: relative;

    .banner-text {
      position: absolute;
      width: 100%;
      top: 50%;
      bottom: auto;
      -khtml-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 1;

      @include breakpoint($mxw1000) {
        top: 56%;
      }

      @include breakpoint($mxw769) {
        background: rgb(0, 1, 180);
        background: linear-gradient(
          90deg,
          rgba(0, 1, 180, 1) 0%,
          rgba(0, 1, 180, 1) 49%,
          rgba(3, 63, 211, 1) 100%
        );
        top: 0;
        position: inherit;
        transform: inherit;
        padding: 30px 15px;
        padding-bottom: 100px;
        border-bottom: solid 1px rgba($c-black, 0.1);
      }

      h1,
      h2 {
        color: $c-white;
        font-size: 48px;
        font-weight: 700;
        line-height: 1.4;

        @include breakpoint($mxw768) {
          font-size: 24px;
        }

        small {
          text-transform: uppercase;
          color: $c-white;
          font-size: 24px;
          display: block;
          font-weight: 700;
        }
      }

      h3 {
        font-size: 26px;
        font-weight: 700;
        color: $c-white;

        @include breakpoint($mxw768) {
          font-size: 16px;
          margin-top: 0px;
        }
      }

      p {
        font-size: 16px;
        color: $c-white;

        @include breakpoint($mxw768) {
          font-size: 12px;
        }
      }

      .btn {
        min-width: 190px;

        @include breakpoint($mxw768) {
          min-width: 120px;
        }
      }
    }
  }

  &.inner {
    margin-top: -72px;

    img {
      z-index: -1;
      position: relative;
    }
  }
}

.brdcm-sec {
  position: relative;
  z-index: 1;
}

.list-top-mn {
  display: flex;
  justify-content: flex-end;

  @include breakpoint($mxw992) {
    padding: 15px 0 0;
    justify-content: center;
  }

  li {
    display: flex;
    align-items: center;
    padding-left: 30px;
    @include breakpoint($mxw769) {
      padding-left: 20px;
      &:first-child {
        padding-left: 0px;
      }
    }

    &.search {
      width: 320px;
      @include breakpoint($mxw992) {
        width: 100%;
      }
      .form-control {
        height: 40px;
        padding: 10px 25px 10px 30px;
        background: url("../images/search-icon.svg") no-repeat;
        background-position: 0px center;
        background-size: 18px;
        border: 0px;
        border-bottom: solid 1px #8d8d98;
        border-radius: 0px;
        font-size: 14px;
        font-weight: 300;
        &:focus{
          outline: none;
          box-shadow: none;
          border-bottom: solid 1px #ffc107;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint($mxw769) {
        justify-content: space-between;
        align-items: flex-start;
        height: 33px;
        overflow: hidden;
      }
    }
  }

  .btn {
    &.btn-bordered {
      display: flex;
      align-items: center;

      img {
        padding-right: 10px;
      }
    }
  }

  img {
    @include breakpoint($mxw769) {
      width: 100%;
    }
  }

  .sslink {
    display: flex;
    padding-left: 30px;
  }
}

.typeahead{
  &.dropdown-menu{
    li{
      padding-left: 0px;
      a{
        justify-content: flex-start;
        &:hover{
          background:rgba($c-yellow, 0.1);
        }
      }
    }
  }
}
