.footer-2 {
  background: $c-gray3;
  padding: 40px 0 30px;
  color: $c-gray2;
  margin: 0;
  border-top: $c-yellow solid 10px;
  // margin-top: 30px;
  ul {
    line-height: 2;
  }

  @include breakpoint($mxw769) {
    padding: 20px 0;
   

  }

  h3 {
    font-size: 20px;
    color: $c-white;
    text-transform: lowercase;
    margin-top: 0px;

    @include breakpoint($mxw769) {
      margin-bottom: 8px;
      margin-top: 15px;
    }
  }

  a {
    color: #adadad;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
      color: $c-white;
    }
  }

  .social {
    margin-top: 5px;

    li {
      a {
        i {
          font-size: 22px;
        }

        &:hover {
          color: $c-white;
        }
      }
    }
  }
}

.footer {
  font-size: 14px;
  padding: 15px 0;
  font-size: 13px;

  @include breakpoint($mxw769) {
    // margin-bottom: 90px;
    padding-bottom: 0;

    .text-right {
      text-align: left !important;
      margin: 10px 0;
    }
  }

  ul {
    li {
      &.list-inline-item {
        margin: 0px;
        padding: 0px;

        &:first-child {
          a {
            padding-left: 0px;
          }

        }
      }

      &.list-inline-item:not(:last-child) {
        margin: 0px;
        padding: 0px;
      }


      &:after {
        content: "|";
      }

      &:last-child {
        &:after {
          content: "";
        }
      }

      padding: 0px;

      a {
        padding: 0 10px;
        color: $c-black;
        font-size: 13px;
        &:hover{
          text-decoration: underline;
        }

        @include breakpoint($mxw769) {
          padding: 0 5px;
        }
      }
    }
  }

  .dropdown-menu {
    li {
      &:after {
        content: "";
      }

      a {
        padding: 10px;
      }
    }
  }
}

.dropdown-menu {
  border-radius: 0px;
  padding: 0px;
  border: 0px;
  color: $c-black;

  li {
    a {
      color: $c-black;
    }
  }
}