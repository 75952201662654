/*     Float containers fix
=============================== */

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  display: inline-block;
}

a {
  text-decoration: none;
  color: $c-blue;
  transition: all 0.2s linear;
}

a:hover {
  text-decoration: none;
  color: $c-black;
}

a:focus {
  text-decoration: none;
  color: $c-yellow;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }

  &.list {
    margin-left: 30px;

    li {
      list-style: inherit;
      margin-bottom: 15px;
    }
  }
}

b,
strong {
  font-weight: 700;
}

/* Hides from IE-mac \*/

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

.ohidden {
  overflow: hidden;
}

.FL {
  float: left;
}

.FR {
  float: right;
}

.flex {
  display: flex;
}

.stretch {
  flex-flow: stretch;
}

.CTR {
  text-align: center;
}

.TAR {
  text-align: right;
}

.uline {
  text-decoration: underline;
}

.UC {
  text-transform: uppercase;
}

.tCap {
  text-transform: capitalize !important;
}

:focus {
  outline: 0;
}

.PR {
  position: relative;
}

.PA0 {
  padding: 0;
}

.PA5 {
  padding: 5px;
}

.PA7 {
  padding: 7px;
}

.PA10 {
  padding: 10px;
}

.PA12 {
  padding: 12px;
}

.PA15 {
  padding: 15px;
}

.PA20 {
  padding: 20px;
}

.PT3 {
  padding-top: 3px;
}

.PT5 {
  padding-top: 5px;
}

.PT7 {
  padding-top: 7px;
}

.PT8 {
  padding-top: 8px;
}

.PT10 {
  padding-top: 10px;
}

.PT15 {
  padding-top: 15px;
}

.PT20 {
  padding-top: 20px;
}

.PT25 {
  padding-top: 25px;
}

.PT30 {
  padding-top: 30px;
}

.PB5 {
  padding-bottom: 5px;
}

.PB7 {
  padding-bottom: 7px;
}

.PB10 {
  padding-bottom: 10px;
}

.PB15 {
  padding-bottom: 15px;
}

.PB20 {
  padding-bottom: 20px;
}

.PB30 {
  padding-bottom: 30px;
}

.PB25 {
  padding-bottom: 25px;
}

.PL5 {
  padding-left: 5px;
}

.PL10 {
  padding-left: 10px;
}

.PL15 {
  padding-left: 15px;
}

.PL20 {
  padding-left: 20px;
}

.PL25 {
  padding-left: 25px;
}

.PR5 {
  padding-right: 5px;
}

.PR10 {
  padding-right: 10px;
}

.PR15 {
  padding-right: 15px;
}

.PR20 {
  padding-right: 20px;
}

.PL50 {
  padding-left: 50px;
}

.PR50 {
  padding-right: 50px;
}

.p0 {
  padding: 0px !important;
}

.relative {
  position: relative;
}

.MR5 {
  margin-right: 5px;
}

.MR7 {
  margin-right: 7px;
}

.MR10 {
  margin-right: 10px;
}

.MR15 {
  margin-right: 15px;
}

.MR20 {
  margin-right: 20px;
}

.MR25 {
  margin-right: 25px;
}

.MR30 {
  margin-right: 30px;
}

.MR0 {
  margin-right: 0 !important;
}

.ML2 {
  margin-left: 2px;
}

.ML5 {
  margin-left: 5px;
}

.ML7 {
  margin-left: 7px;
}

.ML10 {
  margin-left: 10px;
}

.ML15 {
  margin-left: 15px;
}

.ML20 {
  margin-left: 20px;
}

.ML30 {
  margin-left: 30px;
}

.MT3 {
  margin-top: 3px;
}

.MT5 {
  margin-top: 5px;
}

.MT7 {
  margin-top: 7px;
}

.MT10 {
  margin-top: 10px;
}

.MT12 {
  margin-top: 12px;
}

.MT15 {
  margin-top: 15px;
}

.MT20 {
  margin-top: 20px;
}

.MT25 {
  margin-top: 25px;
}

.MT30 {
  margin-top: 30px;
}

.MT35 {
  margin-top: 35px;
}

.MT40 {
  margin-top: 40px;
}

.MT45 {
  margin-top: 45px;
}

.MT50 {
  margin-top: 50px;
}

.MT70 {
  margin-top: 70px;
}

.MT90 {
  margin-top: 90px;
}

.MB5 {
  margin-bottom: 5px;
}

.MB10 {
  margin-bottom: 10px;
}

.MB15 {
  margin-bottom: 15px;
}

.MB20 {
  margin-bottom: 20px;
}

.MB30 {
  margin-bottom: 30px;
}

body .no-margin-top {
  margin-top: 0px;
}

body .no-pad {
  padding: 0px;
}

body {
  color: $c-black;
  font-size: 15px;
  font-family: "Poppins", sans-serif;

  @include breakpoint($mxw992) {
    font-size: 12px;
  }
  @include breakpoint($mxw768) {
    padding-bottom: 50px;
  }
}

@import "_header";
@import "_footer";

h2,
h1 {
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-weight: 700;
  color: $c-black;

  @include breakpoint($mxw768) {
    font-size: 34px;
  }
}

h3 {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  text-transform: capitalize;

  @include breakpoint($mxw768) {
    font-size: 20px;
  }
}

.btn.fill {
  border-radius: 0px;
  padding: 12px 25px;
  background: transparent
    linear-gradient(90deg, #fac013 0%, #faae13 70%, #fa9813 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 5px;
  color: $c-black;
  font-size: 18px;
  font-weight: 700;
  border: solid 0px $c-yellow;

  @include breakpoint($mxw768) {
    padding: 9px 11px;
    font-size: 12px;
  }

  &:hover {
    background: transparent
      linear-gradient(90deg, #fac013 0%, #fac013 70%, #fac013 100%) 0% 0%
      no-repeat padding-box;
    transition: all 0.5s linear;
  }

  &.outline.btn-default {
    background: inherit;
    border: solid 2px $c-yellow;
    color: $c-black;

    &:hover {
      background: $c-yellow;
      border: solid 2px $c-yellow;
      color: $c-white;
    }

    &.white {
      background: $c-white;
      color: $c-yellow;

      &:hover {
        background: transparent;
        border: solid 2px $c-white;
        color: $c-black;
      }
    }
  }
}

.ban-car {
  .owl-nav {
    position: absolute;
    right: 11%;
    bottom: 5%;
  }

  &.vertical-dots {
    .owl-dots {
      left: 6%;
      margin: 15px 0;
      text-align: center;
      position: absolute;
      top: 40%;
      z-index: 0;

      @include breakpoint($mxw1000) {
        left: 0.5%;
      }

      @include breakpoint($mxw769) {
        margin: 15px auto;
        text-align: center;
        position: relative;
        top: inherit;
      }

      &:after {
        content: "";
        position: absolute;
        width: 2px;
        top: 5px;
        z-index: -2;
        right: 10px;
        height: 73%;
        background: $c-black;

        @include breakpoint($mxw769) {
          display: none;
        }
      }

      .owl-dot {
        display: block;
        margin: 0 5px;
        margin-bottom: 15px;
        transition: all 0.5s linear;

        @include breakpoint($mxw769) {
          margin: 0 5px;
          display: inline-block;
        }

        span {
          width: 12px;
          height: 12px;
          display: block;
          background: $c-black;
          border-radius: 50%;
          transition: all 0.5s linear;
          border: 2px solid $c-white;

          @include breakpoint($mxw769) {
            width: 12px;
            height: 12px;
          }
        }

        &.active {
          span {
            border: 2px solid $c-white;
            background-color: $c-yellow;
          }
        }
      }

      .green & {
        .owl-dot {
          margin-bottom: 0;

          span {
            background: $c-yellow;
          }

          &.active {
            border: 1px solid $c-yellow;

            span {
              background-color: $c-yellow;
            }
          }
        }
      }
    }
  }
}

.owl-dots {
  margin: 15px 0;
  text-align: center;
  position: absolute;
  z-index: 0;
  width: 100%;
  bottom: 15%;

  button,
  a {
    &:focus {
      outline: none;
    }
  }

  @include breakpoint($mxw1000) {
    left: 0.5%;
  }

  @include breakpoint($mxw769) {
    margin: 15px auto;
    text-align: center;
    position: relative;
    top: inherit;
  }

  .owl-dot {
    display: inline-block;
    margin: 0 5px;
    transition: all 0.5s linear;

    @include breakpoint($mxw769) {
      margin: 0 5px;
      display: inline-block;
    }

    span {
      width: 10px;
      height: 10px;
      display: block;
      background: inherit;
      border-radius: 50%;
      transition: all 0.5s linear;
      border: solid 1px $c-yellow;

      @include breakpoint($mxw769) {
        width: 12px;
        height: 12px;
      }
    }

    &.active {
      span {
        background-color: $c-yellow;
      }
    }
  }

  .green & {
    .owl-dot {
      margin-bottom: 0;

      span {
        background: $c-yellow;
      }

      &.active {
        border: 1px solid $c-yellow;

        span {
          background-color: $c-yellow;
        }
      }
    }
  }
}

.car-testi {
  @include breakpoint($mxw768) {
    .MT40.flex-row {
      margin-top: 10px;
    }
  }
}

.car-specialist {
  margin-top: 30px;
}

.owl-nav {
  text-align: right;
  margin-top: 15px;
  width: 100px;
  float: right;

  button,
  a {
    &:focus {
      outline: none;
    }
  }

  .owl-prev,
  .owl-next {
    display: inline-block;
    margin-left: 7px;

    &:hover {
      .fa {
        background: $c-yellow;
        // color: $c-white;
      }
    }

    .fa {
      padding: 2px 9px;
      height: 29px;
      width: 29px;
      border-radius: 50%;
      border: solid 2px $c-blue2;
      text-align: center;
      color: $c-blue2;
      font-size: 22px;
    }
  }
}

.specialist {
  margin: 60px 0 100px;

  @include breakpoint($mxw769) {
    margin: 20px 0 40px;

    .flex-row {
      div {
        &:nth-child(3) {
          h2 {
            margin-top: 120px;
          }
        }
      }
    }
  }

  .specialist-list {
    padding: 0 0 0 40px;
    cursor: pointer;

    &:hover {
      h3 {
        color: $c-yellow;
        border-bottom: solid 2px $c-yellow;
      }

      span {
        border: solid 2px $c-yellow;

        img {
          display: none;

          &.hidden {
            display: inline-block !important;
          }
        }
      }
    }

    span {
      position: absolute;
      padding: 5px;
      border-radius: 50%;
      border: solid 2px $c-gray3;
      height: 40px;
      width: 40px;
      left: 15px;
      top: 40px;
      text-align: center;

      @include breakpoint($mxw768) {
        top: 0;
      }

      img {
        width: 16px;
        display: inline-block;
      }
    }

    h3 {
      display: flex;
      align-items: flex-end;
      font-size: 16px;
      min-height: 40px;
      color: $c-black;
      font-weight: 700;
      border-bottom: solid 2px $c-gray3;
      padding-bottom: 6px;
      padding-left: 7px;
      margin-bottom: 5px;
      position: relative;
    }

    p {
      font-size: 14px;
      padding: 0 7px;
    }
  }

  .more {
    float: left;
    margin-top: 35px;
  }
}

.flex-row {
  display: flex;

  @include breakpoint($mxw769) {
    display: block;
  }

  .center-content-v {
    display: flex;
    align-items: center;
  }

  .doc-line {
    display: flex;
    justify-content: center;

    @include breakpoint($mxw769) {
      display: none;
    }

    .middle-line {
      display: flex;
      width: 2px;
      background: #eaeaed;
      align-items: center;
      margin: 30px 0;

      span {
        display: block;
        background: #f8f8fa;
        border-radius: 50%;
        padding: 20px;
        position: relative;
        left: -40px;
      }
    }
  }
}

.more {
  a {
    font-size: 16px;
    font-weight: 700;

    .fa {
      //margin-left: 10px;
      vertical-align: bottom;
    }

    &:hover {
      color: $c-yellow;
    }
  }
}

.grey-bg {
  background: $c-gray5;

  .filter-abc {
    margin-top: 15px;
    background: $c-white;
  }
}

.book-appoinment {
  text-align: center;
  padding: 50px 0 70px;

  @include breakpoint($mxw768) {
    padding: 50px 0 50px;
  }

  h2 {
    margin-top: 0px;
  }

  .form-inline {
    margin-top: 30px;

    .form-group {
      margin-right: 16px;

      @include breakpoint($mxw769) {
        margin-right: 5px;
      }

      @include breakpoint($mxw768) {
        margin-right: 0px;
        animation-fill-mode: inherit;
      }
    }

    .btn.fill {
      padding: 13px 34px;
      min-width: 120px;
    }
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: $c-black;
  opacity: 1;
}

::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $c-black;
  opacity: 1;
}

::placeholder {
  color: $c-black;
  opacity: 1;
}

.form-control {
  border-radius: 0px;
  padding: 12px 20px;
  border: solid 1px #999;
  height: 55px;
  color: $c-black;
  font-size: 14px;
  border-radius: 6px;
}

.bootstrap-select {
  &.full-width {
    .dropdown-toggle {
      width: 100%;
    }
  }
}

.bootstrap-select > .dropdown-toggle {
  width: 300px;
  border-radius: 0px;
  padding: 16px 30px 15px;
  z-index: 1;
  background: transparent;
  border: solid 1px #999;
  font-size: 16px;

  @include breakpoint($mxw1000) {
    width: 220px;
  }

  @include breakpoint($mxw769) {
    width: 170px;
  }

  @include breakpoint($mxw768) {
    width: 100%;
  }
}

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  background: $c-white;
  height: 54px;
}

.facilities {
  padding: 50px 0 70px;

  @include breakpoint($mxw768) {
    padding: 50px 0 50px;
  }

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
}

.car-faci {
  margin-top: 40px;

  .owl-stage-outer {
    height: 266px;

    @include breakpoint($mxw768) {
      height: auto;
    }
  }
}

figure {
  &.border-bottom-img {
    position: relative;

    &:after {
      content: "";
      width: 20%;
      height: 10px;
      background: $c-yellow;
      position: absolute;
      bottom: -10px;
      left: 0px;
      z-index: 1;
    }
  }
}

.faci-box {
  display: flex;

  @include breakpoint($mxw768) {
    display: block;
  }

  figure {
    display: flex;
    position: relative;
    flex: 0 0 18em;

    img {
      width: 100%;
    }

    &:after {
      content: "";
      width: 20%;
      height: 10px;
      background: $c-yellow;
      position: absolute;
      bottom: -10px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background: $c-gray4;
    justify-content: center;
    padding: 10px 30px;

    @include breakpoint($mxw768) {
      padding: 20px 15px 10px;
    }

    p {
      text-align: left;
      font-size: 16px;
    }

    h4 {
      color: $c-yellow;
      font-size: 24px;
      font-weight: 700;
    }
  }
}

.about-sec {
  padding: 50px 0 70px;
  text-align: center;

  @include breakpoint($mxw768) {
    padding: 30px 0 10px;
  }

  .list-inline {
    margin-top: 20px;

    li {
      a {
        color: $c-black;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        padding: 10px 15px;

        @include breakpoint($mxw768) {
          padding: 10px 5px;
        }

        &:hover {
          color: $c-yellow;
        }
      }

      &.active {
        a {
          font-weight: 700;
          color: $c-yellow;
          border-bottom: solid 3px $c-yellow;
        }
      }
    }
  }

  .tab-content {
    margin-top: 70px;

    @include breakpoint($mxw768) {
      margin-top: 30px;
    }

    figure {
      position: relative;

      &:after {
        content: "";
        width: 20%;
        height: 10px;
        background: $c-yellow;
        position: absolute;
        bottom: -10px;
        left: 0;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;

      @include breakpoint($mxw768) {
        padding: 30px 0;
      }
    }

    h4 {
      text-align: left;
      color: $c-yellow;
      font-size: 24px;
      font-weight: 700;
    }

    p {
      text-align: left;
      margin-top: 20px;
      font-size: 16px;
    }
  }
}

.award {
  padding: 50px 0 70px;
  text-align: center;

  .flex-row {
    .grey-bg {
      background: $c-gray4;
    }
  }

  @include breakpoint($mxw768) {
    padding: 50px 0 30px;

    .row {
      &.flex-row {
        display: flex;
        flex-direction: column;

        &:nth-child(3) {
          display: flex;
          flex-direction: column-reverse;
        }

        &.MT70 {
          margin-top: 30px;
        }
      }
    }
  }

  .content {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint($mxw769) {
      padding: 0;
    }

    @include breakpoint($mxw768) {
      padding: 20px 0 30px;
    }

    h4 {
      color: $c-yellow;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }

    p {
      text-align: left;
      margin-top: 20px;
      font-size: 16px;

      @include breakpoint($mxw768) {
        margin-top: 10px;
      }
    }
  }
}

.green-bg {
  background: $c-yellow;
  color: $c-white;

  hr {
    margin: 0px;
    width: 40px;
    display: inline-block;
    border: solid 2px;
  }

  h2 {
    color: $c-white;
  }

  a {
    color: $c-white;

    &:hover {
      color: $c-white;
    }
  }

  .owl-nav {
    margin-top: 0px;

    .owl-prev,
    .owl-next {
      &:hover {
        .fa {
          background: $c-white;
          color: $c-yellow;
        }
      }

      .fa {
        border: solid 2px $c-white;
        color: $c-white;
      }
    }
  }
}

.testimonials {
  padding: 50px 0 70px;
  text-align: center;

  @include breakpoint($mxw768) {
    padding: 30px 0 40px;
  }

  .content {
    padding: 0 30px;

    @include breakpoint($mxw769) {
      padding: 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;

    p,
    h4,
    h5 {
      text-align: left;
    }

    p {
      margin-top: 10px;
      font-size: 16px;
    }

    h4 {
      font-size: 34px;
      font-weight: 700;
    }

    h5 {
      font-size: 24px;
      margin-top: 0;
    }
  }

  figure {
    &.border-bottom-img {
      &:after {
        background: #ffffff;
        bottom: -2px;
        left: 0px;
      }
    }
  }
}

.patient-tools {
  position: fixed;
  z-index: 2;
  right: 0;
  top: 50%;
  bottom: auto;
  -khtml-transform: translateY(-50%);
  transform: translateY(-50%);

  @include breakpoint($mxw1000) {
    top: inherit;
    bottom: 0;
    -khtml-transform: inherit;
    transform: inherit;
    width: auto;
  }

  @include breakpoint($mxw769) {
    width: 100%;
    background: $c-white;
    text-align: center;
  }

  .title {
    writing-mode: tb-rl;
    text-orientation: inherit;
    position: absolute;
    top: 0;
    background: $c-yellow;
    font-weight: 700;
    right: 60px;
    padding: 10px;
    height: 100%;
    color: $c-white;
    text-align: center;
    border: solid 1px $c-white;

    @include breakpoint($mxw1000) {
      writing-mode: inherit;
      text-orientation: inherit;
      position: initial;
    }
  }

  li {
    // border-bottom: 1px solid $c-white;
    height: 60px;

    @include breakpoint($mxw1000) {
      display: inline-block;
    }

    &:last-child {
      border-bottom: 0;
    }

    a {
      display: inline-block;
      width: 60px;
      height: 60px;
      background: $c-white;
      text-align: center;
      position: absolute;
      right: 0;
      border-bottom: solid 1px rgba($c-black, 0.1);
      transition: width 0.5s ease;

      @include breakpoint($mxw1000) {
        bottom: 0;
        position: inherit;
      }

      &.active {
        background: $c-yellow;
        width: 220px;
        height: 60px;
        display: -ms-flexbox;
        display: flex;
        padding: 15px;
        font-size: 14px;
        font-weight: 700;

        span {
          display: block;
          line-height: 2;
        }

        img {
          margin: 0 10px 0 0;
        }
      }

      img {
        height: 30px;
        width: auto;
        margin-top: 15px;
      }

      span {
        display: none;
        color: #ffffff;
        text-align: left;
      }
    }
  }
}

// .modal-backdrop {
//   background: $c-yellow;
//   &.in {
//     opacity: 1;
//   }
// }

// .modal-dialog {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%) !important;
//   margin: 0;
// }
// .modal-content {
//   border-radius: 0px;
//   background: $c-yellow;
//   color: $c-white;
//   padding: 0 40px 40px;
//   box-shadow: inherit;
//   border: 0px;
//   @include breakpoint($mxw768) {
//     .animated {
//       animation-fill-mode: backwards;
//     }
//   }
//   .modal-body {
//     padding: 0;

//     .form-inline {
//       background: #fff;
//       padding: 15px;
//       border: solid 20px rgba(0, 172, 125, 0.76);
//       text-align: center;

//       .bootstrap-select > .dropdown-toggle {
//         width: 170px;
//       }
//     }
//   }

//   .modal-header {
//     border-bottom: solid 0px rgba($c-black, 0.3);
//     height: 0px;
//     padding: 0px;
//     .close {
//       margin-top: -2px;
//       position: absolute;
//       right: 40px;
//       top: -42px;
//       font-size: 50px;
//       font-weight: normal;
//       line-height: 1;
//       color: #fff;
//       text-shadow: inherit;
//       opacity: 1;
//     }
//     .modal-title {
//       font-size: 28px;
//       font-weight: 700;
//     }
//   }
// }

/* ViewPort calculate */

// @function get-vw($target) {
//   $vw-context: 1440 * 0.01 * 1px;
//   @return $target / $vw-context * 1vw;
// }

.brdcm-sec {
  .breadcrumb {
    background: inherit;
    padding: 8px 0px;

    li {
      a {
        display: inline-block;
      }
    }
  }
}

.search-bx {
  min-height: 100px;
  margin-top: -70px;
  padding-top: 80px;
  border-bottom: solid 1px #dadada;
}

.search-bx-form {
  &.form-inline {
    &.full-width {
      margin: 15px 0;
      margin-bottom: 50px;

      .form-group {
        width: 19.52%;

        button {
          width: 100%;
        }

        &:first-child {
          width: 80%;

          input.form-control {
            width: 100%;
          }
        }
      }
    }

    .form-group {
      vertical-align: top;
    }

    input.form-control {
      width: 360px;

      @include breakpoint($mxw768) {
        width: 320px;
      }
    }

    .btn.fill {
      padding: 13px 34px;
      min-width: 120px;
    }

    .all-d-link {
      font-weight: 400;
      padding: 10px 0 0 20px;
    }
  }
}

.search-listing {
  h3 {
    font-weight: 700;
  }
}

.right-bx {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 30px;
  border-left: solid 1px $c-gray5;

  h3 {
    font-size: 20px;

    span {
      color: $c-yellow;
    }
  }
}

.left-bx {
  padding-top: 40px;
  padding-bottom: 40px;

  h3 {
    font-size: 20px;
    margin-bottom: 40px;
    color: $c-black;

    a {
      float: right;
      font-size: 12px;
      text-decoration: underline;
      padding-top: 5px;
    }
  }

  h4 {
    margin-top: 30px;

    a {
      font-weight: 700;
      color: $c-black;

      i {
        float: right;
        color: $c-yellow;
      }
    }
  }

  p {
    margin-top: 10px;
    padding-left: 15px;
  }

  ul {
    li {
      padding-top: 5px;

      label {
        font-weight: 400;
      }
    }
  }
}

.assistance-bx {
  padding: 30px;
  background: $c-gray5;

  .media-left {
    padding-right: 20px;
    border-right: solid 1px $c-gray2;
    vertical-align: middle;
  }

  .media-body {
    padding-left: 20px;

    h4 {
      font-size: 22px;
      font-weight: 700;
      color: $c-yellow;
    }

    p {
      font-size: 14px;

      strong {
        font-weight: 700;
      }
    }
  }
}

.profile-bx {
  .share-bx {
    position: absolute;
    right: 15px;
    top: -90px;
    text-align: right;

    .btn.fill {
      font-size: 14px;
    }

    .dropdown-menu {
      left: inherit;
      right: 0px;
    }
  }

  .profile-content {
    padding-left: 30px;
  }

  padding-bottom: 40px;

  h2 {
    color: $c-yellow;
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 40px;
  }

  h3 {
    margin-top: 5px;
    font-weight: 700;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 0px;
  }

  .btn.fill {
    padding: 7px 15px;
    font-size: 16px;
    margin: 25px 0;
  }

  figure {
    img {
      width: 100%;
    }
  }

  .dl-horizontal {
    dt {
      text-align: left;
      width: 90px;
      padding-top: 3px;
    }

    dd {
      margin-left: 90px;
      padding-top: 3px;
    }
  }
}

.doc-profile-full {
  &.faqs {
    h3 {
      font-weight: 600;
    }
  }

  margin: 50px 0;

  .panel-group {
    .panel {
      padding: 15px 20px;
      border-radius: 0px;
      box-shadow: inherit;
      margin-top: 20px;

      &:first-child {
        margin-top: 0px;
      }

      h3 {
        margin: 0px;
        font-weight: 700;
        font-size: 22px;

        a {
          color: $c-black;

          .fa {
            float: right;
            font-size: 32px;
            margin-top: -8px;
            color: $c-yellow;
          }
        }
      }

      h4 {
        color: $c-yellow;
        font-weight: 600;
        margin-bottom: 3px;
        margin-top: 18px;
        font-size: 16px;
      }

      ul {
        margin-top: 15px;

        li {
          padding-top: 3px;
        }
      }
    }
  }
}

.doc-profile-full .panel-group {
  &.sub-accord {
    margin-bottom: 0px;

    .panel {
      border: none;
      padding-bottom: 0px;
      margin-top: 0px;
    }

    h3 {
      padding: 20px 0;

      a .fa {
        float: left;
        padding-right: 20px;
        margin-top: -3px;
      }
    }
  }
}

body .asistance-ver {
  padding: 10px 30px;
  background: $c-gray5;

  &.emer-call {
    padding: 0px;

    .content {
      padding: 10px 15px 5px;
      text-align: center;

      h3 {
        font-size: 33px;
        color: $c-black;
        line-height: 1.7;
        margin-top: 0px;

        small {
          font-size: 20px;
          color: $c-black;
          font-weight: 600;
          margin-top: 2px;
        }
      }

      h4 {
        font-size: 20px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        margin-top: 15px;

        &:last-child {
          border: 0px;
          padding-top: 0px;
        }
      }
    }
  }

  h2 {
    font-size: 26px;
    color: $c-yellow;
    font-weight: 700;
    margin-top: 30px;
  }

  h3 {
    font-size: 18px;
    color: $c-yellow;
    font-weight: 700;
    margin-top: 30px;
  }

  ul {
    margin-bottom: 20px;

    li {
      padding-bottom: 6px;

      i {
        font-size: 18px;
        padding-right: 10px;
      }

      a {
        color: $c-black;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  small {
    font-size: 13px;
    line-height: 15px;
    display: block;
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 20px;

    strong {
      font-weight: 700;
      display: block;
    }

    &:last-child {
      border-top: solid 1px $c-gray2;
      padding-top: 10px;
      margin-top: 26px;
    }
  }

  .btn.fill {
    padding: 7px 15px;
    font-size: 16px;
    display: block;
    margin: 8px 0;

    &.outline {
      background: $c-white;
      border: solid 1px $c-yellow;

      &:hover {
        background: $c-yellow;
      }
    }
  }
}

.one-10-of {
  margin: 25px 0;
  font-size: 14px;

  span {
    color: $c-yellow;
  }
}

.result-per-page {
  &.list-inline {
    margin: 25px 0;
    font-size: 14px;

    li {
      padding: 0px;

      &:first-child {
        padding-right: 15px;
      }

      a {
        color: $c-black;
        padding: 2px 5px;

        &:hover {
          background: $c-yellow;
          color: $c-white;
        }
      }

      &.active {
        a {
          background: $c-yellow;
          color: $c-white;
        }
      }
    }
  }
}

.listing-container {
  border-top: solid 1px $c-gray5;
}

.list-doc {
  padding: 60px 0;
  border-bottom: solid 1px $c-gray5;

  figure {
    img {
      width: 100%;
    }
  }

  h3 {
    font-size: 34px;
    color: $c-yellow;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    font-weight: 700;
    font-size: 26px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .btn.fill {
    margin-top: 30px;
    padding: 7px 15px;
    font-size: 16px;
  }

  .list-spcl {
    padding-left: 15px;

    h4 {
      font-size: 20px;
    }

    ul {
      li {
        padding-top: 4px;
      }
    }
  }
}

.view-link {
  font-size: 14px;
  font-weight: 700;
}

.cust-pag {
  border-radius: 0px;
  border: 0px;

  li {
    &:first-child,
    &:last-child {
      a {
        border-radius: 0px;
        background: inherit;
        color: $c-yellow;
      }
    }

    &.active {
      a {
        background: $c-yellow;
        z-index: 1;

        &:hover {
          background: $c-yellow;
        }
      }
    }

    a {
      font-size: 12px;
      padding: 6px 10px;
      font-weight: 700;
      border: 0px;
      border-radius: 0px;
      color: $c-black;
      background: $c-gray5;

      &:hover {
        background: $c-yellow;
        color: $c-white;
      }
    }
  }
}

/* The Check box */
.inp-cont {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  margin-top: 5px;

  &:last-child {
    margin-left: 0px;
  }

  &:hover input ~ .checkmark {
    background-color: transparent;
  }

  &.radio {
    .checkmark {
      border-radius: 50%;
      height: 18px;
      width: 18px;

      &:after {
        left: 7px;
        top: 4px;
        width: 5px;
        height: 9px;
      }
    }
  }

  label {
    padding-left: 10px;

    &:hover {
      color: $c-yellow;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $c-yellow;
      border: 0px;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $c-white;
    border: solid 1px $c-gray2;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 5px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.filter-abc {
  background: $c-gray5;
  margin-top: 30px;

  ul {
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    li {
      padding: 0px;
      font-size: 12px;
      width: 100%;
      font-weight: 700;

      &:first-child {
        display: flex;
        flex: 0 0 6em;
        align-items: center;
        justify-content: center;
      }

      a {
        padding: 10px 0;
        display: block;
        width: 100%;
        text-align: center;
        color: $c-black;

        &:hover,
        &.active {
          background: $c-yellow;
          color: $c-white;
        }
      }
    }
  }
}

/* The Radio box */
.inp-cont-radio {
  margin-right: 15px;
  display: inline-block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  label {
    padding-left: 3px;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $c-yellow;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $c-white;
    border: solid 1px $c-yellow;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
}

.section .black {
  color: $c-black;
}

.btn.btn-default.fill.glb {
  font-size: 16px;
  padding: 7px 15px;
  margin-right: 10px;

  &.outline {
    border: solid 1px $c-yellow;
    color: $c-yellow;

    &:hover {
      color: $c-white;
    }
  }
}

.appoint-bx {
  padding: 50px 0;

  h2 {
    font-size: 40px;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;

    strong {
      font-weight: 700;
    }
  }
}

.appoint-body {
  margin: 60px 0;

  .center-content-v {
    font-size: 16px;
  }

  h4 {
    font-weight: 700;
    font-size: 28px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.time-slot {
  margin-left: 33px;

  @include breakpoint($mxw768) {
    margin-left: 0px;
  }

  ul {
    li {
      background: #f6f6f6;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: 1px;
      font-weight: 700;
      cursor: pointer;

      &:first-child {
        background: #e9e9e9;
        padding: 10px 20px;
      }

      &.active {
        background: $c-yellow;
        color: $c-white;
      }

      &:hover {
        background: $c-yellow;
        color: $c-white;
      }
    }
  }

  .owl-next,
  .owl-prev {
    height: 100%;
    position: absolute;
    top: 0;
    right: -32px;
    background: #eee;

    .fa {
      position: relative;
      top: 49%;
      display: block;
      border: 0px;
      background: inherit;
      border-radius: 0;
    }

    &:hover {
      .fa {
        background: inherit;
        color: $c-black;
      }
    }
  }

  .owl-prev {
    right: inherit;
    left: -39px;
  }
}

.btn.btn-link {
  color: $c-yellow;
  text-decoration: underline;
}

.stepwizard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .stepwizard-step {
    &.completed {
      &.done {
        p {
          color: $c-yellow;
          font-size: 14px;
        }

        .btn-circle.btn[disabled] {
          background: $c-yellow;
          color: $c-white;
        }
      }

      p {
        color: $c-red;
      }

      .btn-circle.btn[disabled] {
        background: $c-red;
      }
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      color: $c-black;
      margin-left: 0px;
      font-weight: 700;
    }

    &:first-child {
      text-align: center;

      p {
        margin-left: -2px;
      }
    }

    &:last-child {
      text-align: center;

      p {
        margin-right: 0;
        text-align: center;
      }
    }
  }
}

.stepwizard {
  display: table;
  width: 50%;
  position: relative;
  z-index: 2;

  @include breakpoint($mxw768) {
    width: 100%;
  }
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 30px;
  bottom: 0;
  right: 20px;
  position: absolute;
  content: " ";
  width: 85%;
  height: 2px;
  background-color: $c-gray5;
  z-index: -1;
}

.write-stepWiz {
  .stepwizard-row {
    &.in-step2 {
      &:after {
        width: 85%;
      }
    }
  }
}

.stepwizard-row {
  &.in-step2 {
    &:after {
      width: 45%;
    }
  }

  &.in-step3 {
    &:after {
      width: 85%;
    }
  }
}

.stepwizard-row:after {
  top: 30px;
  bottom: 0;
  right: inherit;
  left: 10px;
  position: absolute;
  content: " ";
  width: 0%;
  height: 2px;
  background-color: $c-yellow;
  z-index: -1;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 60px;
  height: 60px;
  text-align: center;
  padding: 11px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 50%;
  background: $c-gray5;
  border: 0px;
  color: $c-black;
  font-weight: 700;
  border: 6px solid #fff;
  cursor: unset;

  &:hover {
    background: $c-gray5;
    border-color: #fff;
  }

  &:active {
    box-shadow: inherit;

    &:hover {
      color: $c-white;
      border-color: #fff;
    }
  }

  &.btn[disabled] {
    cursor: unset;

    &:hover {
      background: $c-gray5;
      border-color: $c-white;
    }

    &:active {
      &:hover {
        color: $c-white;
      }
    }

    opacity: 1;
  }
}

input.date {
  background: url("../images/date-icon.png") no-repeat 95% center;
}

.cust-sml-card {
  &.media {
    margin-top: 10px;
  }

  .media-body {
    vertical-align: middle;
    padding-left: 15px;
  }

  h4 {
    font-size: 20px;
    margin: 0px;
    color: $c-yellow;
  }

  h5 {
    font-weight: 700;
    margin-top: 5px;
  }

  .view-link {
    font-weight: normal;
  }
}

.subtitle {
  font-size: 18px;
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;

  h3 {
    font-weight: 700;
    color: $c-yellow;
    font-size: 34px;
  }

  .share-bx {
    position: absolute;
    right: 15px;
    top: 20px;
  }

  .btn.fill {
    padding: 7px 15px;
    font-size: 16px;
    margin: 15px 0;

    &.outline {
      background: $c-white;
      border: solid 1px $c-yellow;

      &:hover {
        background: $c-yellow;
        color: $c-white;
      }
    }
  }

  .tab-list.list-inline {
    margin-top: 20px;

    li {
      a {
        color: $c-black;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        padding: 10px 0px;
        margin-right: 10px;

        @include breakpoint($mxw768) {
          padding: 10px 5px;
        }

        &:hover {
          color: $c-yellow;
        }
      }

      &.active {
        a {
          font-weight: 700;
          color: $c-yellow;
          border-bottom: solid 3px $c-yellow;
        }
      }
    }
  }

  .tab-container {
    padding: 10px 0;
  }

  .tab-content {
    margin-top: 60px;

    h4 {
      font-size: 26px;
      color: $c-black;
      font-weight: 700;
      margin-bottom: 30px;
    }
  }

  .view-link {
    font-size: 16px;
  }
}

hr {
  border-top: solid 1px $c-gray2;
}

.ailment-listing {
  h3 {
    font-weight: 700;
    color: $c-yellow;
    font-size: 34px;
  }

  .list {
    border-bottom: solid 1px $c-gray2;
    padding-bottom: 20px;
    padding-top: 20px;

    &.spl-list {
      padding-bottom: 30px;
      padding-top: 40px;
    }

    h3 {
      margin-top: 0px;
      margin-bottom: 20px;
    }

    h4 {
      font-weight: 700;
      color: $c-yellow;
      font-size: 24px;
    }

    .btn.fill {
      margin: 5px 0;
      display: block;
      text-align: center;
    }

    .flex-row {
      .flex-row {
        align-items: center;
      }
    }

    .spl-icon {
      display: block;
      padding: 5px;
      border-radius: 50%;
      border: solid 2px #6d6d6d;
      height: 40px;
      width: 40px;
      left: 15px;
      top: 40px;
      text-align: center;
      transform: scale3d(1.5, 1.5, 1.5);

      img {
        width: 16px;
        display: inline-block;
      }
    }
  }
}

body .card-1 {
  text-align: left;
  padding-bottom: 0px;
  margin: 15px;

  .content {
    background: #f3f3f3;
    padding: 10px 20px;
  }

  figure {
    border-bottom: solid 1px $c-gray5;
    padding-bottom: 0;
    margin-bottom: -30px;
    padding-left: 20px;

    img {
      height: 120px;
      border: solid 8px #fff;
      box-shadow: 1px 1px 0px 0px #d2d2d2;
    }
  }

  h3 {
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 27px;
    font-size: 24px;
  }

  h5 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 7px;
  }

  .btn.fill {
    margin: 10px 0;
  }
}

.spl-filter-bx {
  margin-bottom: 10px;

  hr {
    margin-top: 35px;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .filter-abc {
    margin-top: 0px;

    ul li a {
      padding: 18px 0;
    }
  }
}

.cond-list {
  font-size: 18px;

  li {
    padding: 5px 0;
  }
}

.hos-list {
  h5 {
    font-size: 20px;
  }

  .list {
    margin-top: 50px;
    padding-bottom: 50px;
    border-bottom: solid 1px $c-gray2;

    figure {
      // margin-right: 30px;
      img {
        width: 100%;
      }
    }

    .list-spcl {
      h5 {
        min-height: 40px;
        font-size: 20px;
        color: $c-black;
        font-weight: 700;
        margin-top: 15px;
      }

      ul {
        margin-bottom: 15px;
      }
    }

    h4 {
      font-size: 34px;
      color: $c-yellow;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 5px;
    }

    .heading {
      border-bottom: solid 1px $c-gray2;
      margin-bottom: 15px;
      padding-bottom: 10px;

      p {
        font-size: 18px;
        color: $c-black;
        font-weight: 700;
        small {
          padding-left: 30px;
        }
      }
    }
  }
}

.hos-gallery {
  img {
    width: 100%;
    margin-top: 30px;
  }
}

.hos-desc-list {
  h4 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.hos-heading {
  border-bottom: solid 1px $c-gray2;
  margin-bottom: 15px;
  padding-bottom: 10px;

  p {
    font-size: 18px;
    color: $c-black;
    font-weight: 700;

    small {
      padding-left: 30px;
    }
  }

  &.loc {
    border-bottom: solid 0px $c-gray2;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}

ul.list-con {
  margin-bottom: 20px;

  li {
    padding-bottom: 6px;

    i {
      font-size: 18px;
      padding-right: 10px;
    }

    a {
      color: $c-black;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

ul.green-dot-list {
  li {
    position: relative;
    padding-left: 20px;
    padding-bottom: 7px;

    &:before {
      content: "\2022";
      position: absolute;
      left: 0px;
      top: -6px;
      font-size: 22px;
      color: $c-yellow;
    }
  }
}

.reach-us-bx {
  ul {
    margin-left: 40px;

    li {
      padding-bottom: 7px;
    }
  }
}

.contact {
  .list-inline {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    border-bottom: solid 2px $c-gray2;
    margin-bottom: -1px;
    margin-top: 60px;

    li {
      padding-right: 40px;

      a {
        padding-bottom: 15px;
        margin-bottom: -3px;
        display: inline-block;
        color: $c-black;
        border-bottom: solid 4px transparent;

        &:hover {
          color: $c-yellow;
        }
      }

      &.active {
        a {
          border-bottom: solid 4px $c-yellow;
          color: $c-yellow;
        }
      }
    }
  }
}

.rama-service-list {
  margin-bottom: 60px;

  .col-md-4 {
    padding-right: 8%;
    margin-top: 50px;
  }
}

.icon-with-tag {
  figure {
    text-align: center;
  }

  .content {
    background: $c-gray5;
    color: $c-black;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 600;
    position: relative;

    &:after {
      content: "";
      width: 50%;
      height: 5px;
      background: $c-yellow;
      position: absolute;
      bottom: -5px;
      left: 0px;
    }
  }

  .tag {
    font-size: 26px;
    font-weight: 600;
    color: $c-yellow;
  }

  .title {
    font-size: 20px;
  }
}

.table {
  &.feedback {
    thead {
      tr {
        th {
          background: $c-gray5;
          text-align: center;
          padding: 15px 10px;
          font-size: 14px;
        }
      }
    }

    tbody {
      tr {
        td {
          background: $c-gray4;
          font-size: 14px;
          padding: 10px 30px;
          font-weight: 600;
          vertical-align: middle;
        }
      }
    }
  }
}

.menu-box {
  position: fixed;
  width: 100%;
  top: 119px;
  background: $c-yellow;
  padding: 60px 0;
  // transition-property: all;
  // transition-duration: 0.5s;
  // transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  display: none;

  &.scrolm {
    top: 90px;
  }

  .tabsCont {
    display: none;
    color: $c-white;

    h4 {
      font-size: 36px;
      font-weight: 600;
    }

    h5 {
      font-size: 22px;
      font-weight: 600;
    }

    p {
      padding-bottom: 30px;
      padding-top: 15px;
    }

    a {
      color: $c-white;
      font-size: 16px;

      &:hover {
        color: $c-white;
      }
    }

    ul {
      li {
        padding: 4px 0;
      }
    }

    .menuTab {
      display: none;

      &.act {
        display: block;
      }

      ul {
        float: left;
      }
    }
  }
}

.about {
  &.contact {
    .list-inline {
      text-transform: uppercase;
      margin-top: 0px;
      font-size: 26px;
      background: $c-gray4;
      border-bottom: 0px;

      li {
        padding: 0 10%;

        a {
          padding: 20px 0;
        }
      }
    }

    .about-sec {
      text-align: center;

      p {
        font-size: 16px;
        line-height: 1.5;

        .view-link {
          font-size: 16px;
        }
      }
    }
  }
}

.icn-inline {
  li {
    display: inline-block;
    padding: 40px 3%;
    padding-right: 90px;

    &:first-child {
    }

    &:last-child {
      padding-right: 0px;
    }
  }
}

.about-img-bx {
  display: flex;
  flex-direction: row;

  .img-bx {
    width: 50%;
  }

  .content-bx {
    background: #000;
    color: #fff;
    padding: 20px 80px;
    padding-right: 18%;

    h2 {
      color: $c-white;
      margin-top: 30px;
    }

    p {
      font-size: 16px;
      margin-top: 20px;
    }
  }
}

.founder-sec {
  text-align: center;
  padding: 60px 0;
  margin-bottom: 18%;

  h2 {
    small {
      display: block;
      color: $c-white;
      font-size: 34px;
      padding-top: 7px;
    }
  }

  p {
    font-size: 16px;
    margin-top: 30px;

    .view-link {
      font-size: 16px;
    }
  }

  figure {
    margin-bottom: -33%;
    padding-top: 50px;

    img {
      display: inline-block;
      border: solid 10px #fff;
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.18);
    }
  }
}

.leader-sec {
  padding: 60px 0;
  text-align: center;

  p {
    font-size: 18px;
  }
}

.profile-thumb {
  margin-top: 60px;
  padding: 0 10px;
  cursor: pointer;

  figure {
    img {
      border: solid 5px $c-white;
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.18);
      display: inline-block;
    }
  }

  h3 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 2px;
  }

  p {
    font-size: 16px;
  }

  a {
    font-weight: bold;
    font-size: 16px;
  }
}

.group-sec {
  padding: 60px 0;
  text-align: center;
  padding-bottom: 13%;

  p {
    font-size: 16px;
  }
}

.group-thumb-bx {
  background: $c-gray4;
  margin-top: 30px;
  text-align: center;

  .content {
    padding: 25px 10px;
    padding-top: 70px;
    position: relative;

    h3 {
      font-size: 26px;
      font-weight: 600;
    }

    img {
      position: absolute;
      top: -60px;
      left: 36%;
    }
  }
}

.grp-thumb-sec {
  margin-top: -15%;
  padding: 60px 0;
}

.hos-bg {
  padding: 0px 0 30px;

  figure {
    img {
      width: 100%;
    }
  }

  .margin-minus {
    margin-top: -12%;
  }
}

.about-sec.hos {
  padding: 50px 0;
}

.hosp-card {
  text-align: center;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.18);

  .title {
    background: rgba($c-yellow, 0.9);
    font-size: 34px;
    color: $c-white;
    font-weight: bold;
    padding: 20px;
    line-height: 1.1;
  }

  .content {
    background: $c-white;
    padding: 10px 0 25px;

    h3 {
      font-weight: 600;
      font-size: 20px;
    }

    p {
      padding-top: 10px;

      &.more {
        padding-top: 20px;
      }
    }
  }
}

.pkg-card {
  margin: 0 15px;
  text-align: center;
  margin-top: 60px;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.18);

  figure {
    position: relative;

    span.off {
      display: block;
      position: absolute;
      left: -10px;
      top: -10px;
      z-index: 1;
    }
  }

  .content {
    padding: 20px;

    h3 {
      font-weight: 600;
      font-size: 26px;
    }

    h4 {
      font-size: 34px;
      font-weight: 600;
    }
  }

  .btns {
    display: flex;

    a {
      width: 50%;
    }
  }
}

.spl-card {
  text-align: center;
  padding: 20px;
  padding-top: 40px;
  margin-top: 30px;
  border: solid 1px #ddd;
  transition: all 0.2s linear;

  &:hover {
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.18);
  }

  h3 {
    font-weight: 600;
    font-size: 26px;
  }

  p {
    padding-top: 10px;

    &.more {
      padding-top: 20px;
    }
  }

  figure {
    img {
      width: 60px;
    }
  }
}

.font16 {
  font-size: 16px;
}

.ban-btn-text {
  background: $c-gray4;
  padding: 40px 0;
  text-align: center;

  h3 {
    margin-top: 0px;
    font-weight: 600;
  }

  .btns {
    padding-top: 35px;
  }
}

.explore-sec {
  padding: 0 0 70px;

  h2 {
    text-align: center;
  }
}

.explore-bx {
  background: $c-gray4;
  padding: 25px 30px;
  margin-top: 30px;
  text-align: left;

  h3 {
    font-weight: 600;
    font-size: 24px;
    margin-top: 0px;
  }

  ul {
    li {
      padding: 4px 0;
      font-weight: 600;
    }
  }
}

.wards-sec {
  background: #f5f6f8;
  padding-bottom: 160px;
  padding-top: 40px;
  margin-top: -40px;
}

.wards-card {
  text-align: center;
  padding-top: 20px;

  h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.about-sec {
  &.ward {
    padding: 0px 0 0px;
    text-align: center;
    margin-top: -100px;

    &.last {
      padding: 0px 0 70px;
    }
  }
}

.car-wards {
  .owl-dots {
    bottom: -26%;
  }

  .owl-item.active {
    img {
      transform: scale(0.6);
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.18);
    }

    &.center {
      img {
        transform: scale(1.5);
      }
    }
  }
}

a {
  i {
    &.fa-long-arrow-right {
      transition: all 0.3s ease;
      transform: translate3d(-8px, 0, 0);
      padding-left: 15px;
    }
  }

  &:hover {
    i {
      &.fa-long-arrow-right {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.menuTabLink {
  li {
    a.act {
      padding-left: 20px;
    }
  }
}

.btn {
  &.btn-bordered {
    border: 1px solid $c-gray3;
    border-radius: 10px;
  }
}

.course-sec {
  padding-bottom: 45px;
  margin-top: -80px;
  position: relative;
  z-index: 1;
}

.card {
  background: $c-white;
  padding: 25px;
  border-bottom: solid 7px $c-gray3;
  box-shadow: 0px 13px 23px rgba($c-black, 0.1);
  text-align: center;
  height: 100%;

  a{
    color: $c-black;
  }

  h3 {
    font-size: 20px;
    color: $c-blue2;
    font-weight: 600;
  }

  p {
    padding-top: 5px;
    font-size: 14px;
    margin-bottom: 0px;
  }

  &.college-list {
    padding: 0px;
    border-radius: 6px;
    box-shadow: none;
    border: 0px;
    background: #f8f8f8;
    border-bottom: solid 8px #eee;
    h4 {
      background: #2a2a3e;
      color: $c-white;
      font-size: 14px;
      padding: 15px 20px;
      border-radius: 6px 6px 0 0;
      margin-bottom: 0px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .cnt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      > div {
        padding: 3px 10px;
      }
      .location {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        display: inline-block;
        img {
          margin-right: 7px;
        }
      }
      .cost {
        display: flex;
        img {
          margin-right: 7px;
        }
      }
      .rating {
        .num {
          font-size: 16px;
        }
      }
    }
  }
}

.country-po {
  background: $c-gray;
  padding: 28px 0 70px;
}

.hd {
  font-size: 42px;
  color: #0814a3;
  font-weight: 600;
  @include breakpoint($mxw768) {
    font-size: 24px;
    img {
      width: 35px;
    }
  }
}
.hd1 {
  font-size: 32px;
  color: #0814a3;
  font-weight: 600;
  @include breakpoint($mxw768) {
    font-size: 20px;
    img {
      width: 35px;
    }
  }
}

.hd3 {
  font-size: 24px;
  font-weight: 600;
  color: #020a51;
  line-height: 1.5;
  @include breakpoint($mxw768) {
    font-size: 18px;
  }
}

.hd4 {
  font-size: 20px;
  font-weight: 600;
  color: #002880;
  line-height: 1.3;
}

.abroad-car {
  margin-top: 30px;
  a {
    color: $c-black;
  }
}

.img-card {
  .title {
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
    margin-bottom: 5px;

    @include breakpoint($mxw768) {
      padding-top: 0px;
    }
  }
  figure {
    margin-bottom: 0px;
  }
}

.trending-colleg {
  background: #f0f0f0;
  padding: 45px 0 70px;
  // margin-top: 30px;
  // margin-bottom: -30px;

  .loc-price {
    display: flex;

    // justify-content: space-between;
    p {
      // width: 100%;
      display: flex;
      padding-right: 5px;

      img {
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}

.profile-sec {
  background: $c-gray3;
  color: $c-white;
  padding: 30px 0 0;
}

.counter-list {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint($mxw769) {
    flex-wrap: wrap;
  }

  li {
    margin: 0 60px;
    margin-bottom: 30px;
  }
}

.counter-cnt {
  text-align: center;

  h4 {
    color: $c-yellow;
    font-size: 42px;
    font-weight: 800;
  }

  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.custModal {
  .modal-body {
    padding: 30px;
  }

  .modal-content {
    border-radius: 6px;
    border-width: 0px;
    border-top: solid 0px #fac019;
    border-bottom: solid 0px #0811a3;
  }

  .modal-header {
    background: #068df6;
    color: #fff;
    border-radius: 6px 6px 0 0;

    .close {
      color: #fff;
      opacity: 1;
      font-size: 60px;
      padding: 0px 10px;
    }
  }

  .modal-title {
    font-size: 24px;
    font-weight: 300;
    padding-left: 15px;
    letter-spacing: 1px;
  }

  .col-form-label {
    font-size: 12px;
    // text-transform: uppercase;
  }
  .feedbackForm{
    @include breakpoint($mxw768) {
      overflow: scroll;
    }
  }
}

.close {
  font-size: 36px;
  font-weight: 100;
  text-shadow: none;
  opacity: 0.5;
}

.btn-primary,
.btn {
  font-size: 14px;
  padding: 15px 30px;
  text-transform: uppercase;
  border-radius: 0px;
}

.btn-primary.blue-grd {
  font-size: 20px;
  background: transparent linear-gradient(145deg, #0814a3 0%, #068df6 100%) 0%
    0% no-repeat padding-box;
  border-radius: 6px;
  text-transform: lowercase;
  font-weight: 500;
}
.btn-primary.yellow-grd {
  font-size: 20px;
  background: transparent linear-gradient(145deg, #fac013 0%, #faa313 100%) 0%
    0% no-repeat padding-box;
  border-radius: 6px;
  text-transform: lowercase;
  font-weight: 500;
  color: $c-black;
  border: 0px;
}
.block {
  display: block;
  width: 100%;
}

.error {
  font-size: 11px;
  color: $c-red;
  font-weight: 500;
}

.mMenuToggle {
  display: none;
  position: absolute;
  top: 12px;
  right: 20px;

  @include breakpoint($mxw768) {
    display: inline-block;
  }

  a {
    display: inline-block;

    i {
      font-size: 20px;
    }
  }
}

/* The side navigation menu */
.sidenav {
  opacity: 0;
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 3;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  left: 0;
  background: rgb(0, 1, 180);
  // background: linear-gradient(90deg, rgba(0, 1, 180, 1) 0%, rgba(0, 1, 180, 1) 49%, rgba(3, 63, 211, 1) 100%);
  box-shadow: 5px 0px 200px 30px rgba($c-black, 0.6);
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 60px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */

  @include breakpoint($mxw768) {
  }
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #fff;
  display: block;
  transition: 0.3s;
  text-transform: lowercase;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: -10px;
  right: 8px;
  font-size: 46px;
  font-weight: 200;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.inner-content {
  margin-top: -300px;
  position: relative;
  @include breakpoint($mxw768) {
    margin-top: -60px;
  }

  p {
    font-weight: 300;
    line-height: 1.6;
  }
  .copy {
    padding: 40px 20px;
    padding-top: 0px;
    @include breakpoint($mxw768) {
      padding: 20px 0;
    }
  }
  .col-md-8,
  .col-md-4 {
    .copy {
      padding: 40px 20px;
      padding-top: 0px;
      @include breakpoint($mxw768) {
        padding: 20px 0;
      }
    }
  }
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #068df6;
  .num {
    font-size: 30px;
    font-weight: 600;
    color: $c-black;
  }
}

.list-inline {
  &.college-rating {
    display: flex;
    align-items: center;
    margin-top: 30px;
    @include breakpoint($mxw768) {
      margin-top: 0;
      flex-wrap: wrap;
    }
    li {
      padding-right: 35px;
      @include breakpoint($mxw768) {
        padding-top: 10px;
      }
    }
  }
  &.country {
    margin-left: -7px;
    margin-right: -7px;
    li {
      padding-right: 7px;
      padding-left: 7px;
      padding-bottom: 15px;
      margin-right: 0px;
      @include breakpoint($mxw768) {
        width: 32%;
        img {
          width: 100%;
        }
      }

      // &:last-child {
      //   padding-right: 0px;
      // }

      a {
        color: #020a51;
        text-align: center;

        figcaption {
          font-size: 14px;
          font-weight: 600;
          margin-top: 10px;
        }
      }
    }
  }
  &.college {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
    a {
      color: $c-black;
    }
    li {
      padding: 10px 10px 0;
      width: 25%;

      @include breakpoint($mxw1000) {
        width: 33.3%;
      }

      @include breakpoint($mxw992) {
        width: 50%;
      }

      @include breakpoint($mxw768) {
        width: 100%;
      }
    }
  }
}

.white-bg {
  background: $c-white;
}

.right-sidebar {
  .heading {
    background: #068df6;
    padding: 25px 20px;
    margin-top: 50px;
    border-radius: 6px 6px 0 0;
    @include breakpoint($mxw768) {
      margin-top: 0px;
    }

    .hd3 {
      color: $c-white;
      line-height: 1.3;
    }
    .hd4 {
      color: $c-white;
      font-size: 16px;
      font-weight: 300;
      @include breakpoint($mxw768) {
        font-size: 13px;
      }
    }
  }
}

.form-bx {
  box-shadow: 0px 1px 4px -1px rgba(31, 33, 29, 0.3);
  padding: 25px 20px;
  .form-control {
    border: 0px;
    border-bottom: solid 1px #707070;
    padding-left: 0px;
    color: $c-black;
    height: 48px;
    font-size: 16px;
    border-radius: 0px;

    &:focus {
      box-shadow: none;
      border-color: #0814a3;
    }

    &::placeholder {
      color: $c-black;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $c-black;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $c-black;
    }
  }
  label {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .custom-control {
    label {
      font-size: 14px;
    }
  }

  ul {
    li {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}

.checkbox-container {
  padding-top: 20px;
  label {
    font-size: 14px;
  }

  .custom-control {
    margin-bottom: 20px;

    .custom-control-label::before {
      border-radius: 0px;
      border: solid 1.5px #707070;
    }
  }
}

.full-width {
  width: 100%;
}

.share-ic {
  display: flex;
  padding: 20px;
  a {
    margin-right: 15px;
    line-height: 1.6;
    &.badge {
      padding: 2px 15px;
      font-size: 13px;
      font-weight: 500;
    }
    img {
      width: 70px;
    }
  }
}

.breadcrumb {
  &.custom {
    background: inherit;
    margin-bottom: 0px;
    .breadcrumb-item.active {
      color: $c-white;
      font-weight: 500;
    }
    a {
      color: $c-white;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
      color: $c-white;
    }
  }
}
.auto-head-li-ol {
  h4 {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 600;
    color: #020a51;
    line-height: 1.5;
  }
  ul {
    margin-top: 30px;
    li {
      list-style-type: disc;
      margin-left: 15px;
      margin-bottom: 10px;
    }
  }
  ol {
    padding: 0px;
    margin-top: 30px;
    li {
      margin-left: 15px;
      padding-left: 10px;
      margin-bottom: 10px;
    }
  }

  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: $c-white; // Reset for nesting within parents with `background-color`.
    text-align: center;
    margin-top: 30px;
    th,
    td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }

    thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      background: #020a51;
      border-color: #020a51;
      color: $c-white;
    }

    .table {
      background-color: transparent;
    }
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  

  .table {
    background-color: transparent;
  }
}
.with-dots {
  li {
    list-style-type: disc;
    margin-left: 15px;
    margin-bottom: 10px;
  }
}

.with-number {
  padding: 0px;
  li {
    margin-left: 15px;
    padding-left: 10px;
    margin-bottom: 10px;
  }
}

.list-with-line {
  li {
    padding: 15px 5px;
    border-top: solid 1px #707070;
    display: flex;
    align-items: center;
    &:last-child {
      border-bottom: solid 1px #707070;
    }
    span {
      padding: 0 20px;
      @include breakpoint($mxw768) {
        padding: 0 6px;
        img {
          width: 30px;
        }
      }

      &:first-child {
        padding-left: 0px;
      }
    }
  }
}

.form-grp {
  @include breakpoint($mxw768) {
    position: fixed;
    background: $c-white;
    bottom: 0px;
    left: 0px;
    height: 500px;
    overflow: auto;
    z-index: 1050;
    display: none;
  }
  .close {
    display: none;
    background: $c-white;
    opacity: 0.8;
    z-index: 20;
    padding: 7px 17px;
    @include breakpoint($mxw768) {
      display: block;
      position: fixed;
      right: 0px;
      margin-top: -50px;
    }
  }
}

.floating-btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;
  display: none;
  background: #2a2a3e;
  padding: 10px;
  text-align: center;
  @include breakpoint($mxw768) {
    display: block;
  }

  .btn.fill {
    padding: 6px 30px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 16px;
    text-transform: lowercase;
  }
  .call-btn{
    color: $c-white;
    font-size: 14px;
    border: solid 1px rgba($c-white, 0.37);
    padding: 5px 20px;
    border-radius: 20px;
    display: inline-block;
  }
}

.video-modal .modal-dialog {
  max-width: 800px;
  .modal-body {
    padding: 0px;
  }
  .vid-cls {
    position: absolute;
    top: -60px;
    right: 9px;
    font-size: 50px;
    z-index: 1;
    background: none;
    border: 0;
    color: #fff;
    font-weight: 200;
    opacity: 1;
    &:focus {
      outline: 0px;
    }
  }
}

.secondary-nav,
.normal-tabs {
  padding: 10px 0;
  position: relative;
  background: #fabf11;
  margin-left: -15px;
  margin-right: -15px;

  @include breakpoint($mxw992) {
    overflow: scroll;
    //height: 58px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  @include breakpoint($mxw768) {
    padding: 0;
  }

  ul {
    display: flex;
    margin-left: 20px;
    @include breakpoint($mxw992) {
      margin: 0 auto;
    }

    li {
      a {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin: 10px 15px;
        padding: 0px;
        color: $c-black;
        &.active {
          font-weight: 600;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            background: url("../images/down-arrow.svg") no-repeat;
            height: 12px;
            width: 100%;
            bottom: -10px;
            background-size: 15px;
            background-position: center;

            @include breakpoint($mxw768) {
              bottom: -1px;
            }
          }
        }

        span {
          position: absolute;
          @include center(x);
          bottom: -2px;
          opacity: 0;
          transition: opacity 0.2s ease;
          font-size: 12px;
        }

        @include breakpoint($mxw1000) {
          padding: 10px 7px;
        }

        @include breakpoint($mxw992) {
          margin: 5px;
        }

        &:hover,
        &.active {
          // color: $c-brown1;

          span {
            opacity: 1;
          }
        }
      }
    }

    @include breakpoint($mxw768) {
      flex-wrap: nowrap;
      overflow: auto;
      justify-content: flex-start;

      li {
        a {
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }
  }
  &.sticky {
    position: fixed;
    width: 100%;
    top: 48px;
    left: 0;
    z-index: 2;
    box-shadow: rgba($c-black, 0.2) 0 3px 6px;
    margin-left: 0px;
    margin-right: 0px;
    @include breakpoint($mxw768) {
      top: 0px;
    }
    .nav {
      margin-left: auto;
    }

    .menu-box-stiky,
    .logo-box {
      display: block;

      @include breakpoint($mxw1000) {
        display: none;
      }
    }
  }
}

.download-btn {
  background: #020a51;
  color: $c-white;
  padding: 4px 15px;
  border-radius: 6px;
  &:hover {
    color: $c-white;
    text-decoration: underline;
  }
}
.medical-bx {
  padding: 36px 0;
}
.medi-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  li {
    display: flex;
    margin-top: 20px;
    padding-right: 15px;
    font-size: 14px;
    align-items: center;
    width: 33.3%;
    @include breakpoint($mxw768) {
      font-size: 10px;
      width: 49.3%;
    }
    span {
      padding-right: 10px;
      padding-left: 10px;
      img {
        width: 40px;
      }
    }
  }
}

.medi-box-list {
  display: flex;
  margin-top: 30px;
  font-size: 14px;
  @include breakpoint($mxw768) {
    font-size: 12px;
    flex-wrap: wrap;
  }
  > li {
    margin-top: 15px;
    text-align: center;
    background: #f0f0f0;
    padding: 20px;
    padding-bottom: 30px;
    width: 33.3%;
    @include breakpoint($mxw768) {
      width: 100%;
    }
    &:nth-of-type(even) {
      background-color: #f7f7f7;
    }
    ul {
      margin: 0 20px;
      color: #020a51;
      display: flex;
      flex-wrap: wrap;
      li {
        list-style: disc;
        text-align: left;
        margin-right: 18px;
      }
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
  }
}
.hd4 {
  &.feeSt {
    background: #efefef;
    padding: 10px 20px;
    border-radius: 6px 6px 0 0;
    margin-bottom: -15px;
  }
}

.table {
  &.op1 {
    margin-top: 15px;
    border-bottom: solid 6px #002880;

    th {
      vertical-align: top;
      background: #2a2a3e;
      color: $c-white;
      font-weight: 500;
      font-size: 16px;
      border: solid 1px #fff;
      border-radius: 6px 6px 0 0;

      @include breakpoint($mxw768) {
        font-size: 14px;
      }
      span {
        color: #fac013;
      }
    }
    td {
      font-size: 18px;
      padding: 15px;
      border-radius: 6px;
      @include breakpoint($mxw768) {
        font-size: 13px;
        padding: 12px;
      }
    }
    .semibold {
      color: #002880;
    }
    tfoot {
      background: #068df6;
      color: #fff;
      font-weight: 600;
      font-style: italic;
      td {
        font-size: 22px;
      }
    }
  }
  &.op2 {
    margin-top: 30px;
    td,
    th {
      padding: 15px 40px;
      border-radius: 6px 6px 0 0;
      @include breakpoint($mxw768) {
        padding: 10px 15px;
      }
    }

    th {
      vertical-align: top;
      background: #fac013;
      color: $c-black;
      font-weight: 500;
      font-size: 16px;
      border: solid 1px #fff;
      @include breakpoint($mxw768) {
        font-size: 14px;
      }
      span {
        color: #fac013;
      }
    }
    td {
      font-size: 16px;
      border-radius: 6px;
      color: #002880;
      font-weight: 500;
      @include breakpoint($mxw768) {
        font-size: 13px;
      }
    }
    .semibold {
      color: #002880;
    }
  }
  &.op3 {
    border-radius: 6px;
    th,
    td,
    tr {
      color: #fff;
      background: #068df6;
      font-style: italic;
      font-weight: 600;
      border-color: #002880;
      padding: 13px 20px;
    }
  }
  &.op4 {
    background: #fff;
    td,
    th {
      font-style: italic;
      color: $c-black;
      font-size: 16px;
      padding: 15px 30px;

      @include breakpoint($mxw768) {
        font-size: 14px;
        padding: 10px 10px;
      }
    }
  }
}

.professional-fee {
  background: #00102c;
  color: #fff;
  border-radius: 6px;
  .hd4 {
    padding: 10px 20px;
    color: #fff;
    font-style: italic;
    margin-bottom: 0px;
    img {
      padding-left: 10px;
    }
  }
}

.pro-services {
  background: #00102c;
  color: #fff;
  border-radius: 6px;

  margin-top: 30px;
  .hd4 {
    padding: 10px 20px;
    color: #fff;
    font-style: italic;
    margin-bottom: 0px;
    text-transform: lowercase;
    font-size: 18px;
  }
}
.semibold {
  font-weight: 500;
}

.note {
  font-size: 13px;
  line-height: 1.3;
  font-style: italic;
  @include breakpoint($mxw768) {
    font-size: 11px;
  }
}

.discount-bx {
  background: #020a51;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  font-size: 20px;
  color: $c-white;
  @include breakpoint($mxw768) {
    padding: 15px 20px;
    font-size: 15px;
  }
  span {
    padding-right: 10px;
    &:last-child {
      padding-right: 0px;
    }
  }
  .yellow {
    color: #fac013;
    font-weight: 500;
  }
  .fee {
    color: #fff;
    font-weight: 600;
    text-decoration: line-through;
  }
  .green {
    font-weight: 600;
    color: #93fd3e;
    small {
      font-size: 10px;
    }
  }
}

.usd-rate {
  font-size: 11px;
  padding: 10px 15px;
  background: #f5f5f5;
  border-radius: 6px;
}

.rating-logo {
  margin-top: 40px;
}
.proBar-bx {
  h4 {
    margin-top: 20px;
    font-size: 20px;
  }
  .progress {
    margin-top: 10px;
    height: 20px;
    background: #ffb100;
  }
  .progress-bar {
    background: #003185;
    border-radius: 6px 0 0 6px;
  }
}

.final-rating-score {
  font-size: 32px;
  font-weight: bold;
  font-style: italic;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 44px;
    color: #020a51;
  }
}

.image-gallery-bx {
  &.withoutSpace {
    margin-right: -15px;
    margin-left: -15px;
    .img-fluid{
      border: solid 0.5px #fff;
    }
    .row {
      a {
        margin: 0px;
        margin-top: 0px;
        display: block;
        height: auto;
        @include breakpoint($mxw769) {
          width: 50%;
          max-height: 200px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        @include breakpoint($mxw768) {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  margin: 0;
  margin-top: 20px;
  .row {
    a {
      margin: 5px;
      margin-top: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;
      overflow: hidden;
    }
  }
}
.hd5 {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: -10px;
}

.ekko-lightbox {
  &.modal {
    .modal-content {
      background: transparent;
      border: 0px;
    }
    .modal-body {
      padding: 0px;
    }
    .modal-header {
      border: 0px;
      .close {
        color: #fff;
        opacity: 1;
        font-size: 40px;
      }
    }
    .ekko-lightbox-nav-overlay a {
      color: #fabf11;
      font-size: 40px;
      opacity: 1;
    }
  }
}

.popular-course {
  padding-top: 60px;
  padding-bottom: 60px;
}

.card-course {
  margin-top: 50px;
  h4 {
    font-size: 18px;
    color: #020a51;
    margin-bottom: 10px;
  }
  ul {
    li {
      list-style-type: disc;
      margin-left: 15px;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}

.services-sec {
  background: #f7f7f7;
  padding-top: 60px;
  padding-bottom: 60px;
}

.recognitions {
  padding-top: 0px;
  padding-bottom: 70px;
  background: #f0f0f0;

}
.news-sec {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #f0f0f0;
  a {
    display: block;
  }
}

.blog-sec {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #f7f7f7;
}

.blog-card {
  box-shadow: 0px 0px 6px 0px rgba($c-black, 0.2);
  position: relative;
  margin-top: 30px;
  a {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 1;
  }
  figure {
    margin: 0px;
  }
  .date {
    color: #068df6;
    font-size: 11px;
  }
  .cnt {
    background: #fff;
    padding: 10px;
    .title {
      color: #020a51;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
}

.btn-link {
  color: #020a51;
}
.hd2 {
  &.yellow-full-width {
    background: #fac013;
    padding: 15px 40px;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 24px;
    margin-bottom: 0px;

    + .row {
      background: #f7f7f7;
      padding: 0 20px;
      + .row {
        background: #f7f7f7;
        margin-bottom: 40px;
        padding: 0 20px;
        padding-bottom: 30px;
      }
    }
  }
}

.twitter-typeahead{
  width: 100%;
}

.typeahead,
.tt-query,
.tt-hint {
  width: 100%;
  height: 30px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 100%;
  margin:  0;
  padding:  0;
  background-color: #fff;
  border: 1px solid #ccc;
  
}

.tt-suggestion {
  padding: 0px;
  font-size: 14px;
  line-height: 1.1;
  margin-bottom: 0px;
  &:hover{
    background:$c-white;
  }
    a.link{
      justify-content: start;
      padding: 10px;
      border-top: solid 1px #eee;
      color: $c-black;
      &:hover{
        background: rgba($c-yellow, 0.3);
      }
    }
    &:first-child{
      a.link{
        border-top: 0px;
      }
       
    }

  
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;

}

.tt-suggestion.tt-cursor {
  color: #fff;


}

.tt-suggestion p {
  margin: 0;
}

.empty-message{
  padding: 10px;
    font-size: 12px;
}
