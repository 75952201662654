/* Accordian style */
.accordian {

    /* common accordian like FAQ */
    .trgr {
        // @extend .white-box;
        padding: 20px 0 20px 0;
        margin-top: 0;
        position: relative;
        cursor: pointer;
        box-shadow: none;
        border-bottom: 1px solid $c-gray2;
        border-radius: 0px;
        font-weight: 500;

        &::before {
            position: absolute;
            width: 15px;
            height: 15px;
            font-family: icomoon;
            content: "\e900";
            right: 30px;
            top: 20px;
            color: $c-red;
            @include breakpoint($mxw768) {
                right: 0px;
            }
        }

        &.act {
            // border-radius: 5px 5px 0 0;
            border-bottom: 0px solid;

            &::before {
                content: "\e903";
            }
        }
    }

    .tog_cont {
        // @extend .white-box;
        // border-radius: 0 0 5px 5px;
        // border-top: 1px solid $c-red;
        // padding: 20px 30px;
        // margin-bottom: 1rem;

        border-radius: 0;
        padding: 20px 0;
        margin-bottom: 0px;
        box-shadow: none;
        border-bottom: 1px solid $c-gray2;

        .block {
            display: block;
        }
    }

    @include breakpoint($mxw768) {
        .trgr {
            padding: 20px 30px 20px 20px;
            padding-left: 0px;
        }

        .tog_cont {
            padding: 20px;
            padding-left: 0px;
        }
    }

    /* common accordian like FAQ end */

    /* Product Detail page Eligibility */
    &.op1 {
        .trgr {
            box-shadow: none;
            font-size: 36px;
            font-weight: 300;
            border-bottom: 1px solid $c-gray2;
            padding: 10px 50px 10px 0;
            border-radius: 0;
            line-height: 1;

            @include breakpoint($mxw1000) {
                font-size: 24px;
            }

            &::before {
                content: "\e906";
                height: auto;
                width: auto;
                right: 0;
                color: $c-black;
                top: 15px;
                font-size: 24px;

                @include breakpoint($mxw1000) {
                    font-size: 18px;
                }
            }

            &.act {
                &::before {
                    content: "\e905";
                }
            }
        }

        .tog_cont {
            box-shadow: none;
            border: 0;
            padding-left: 20px;
            margin: 0;

            @include breakpoint($mxw1000) {
                padding-bottom: 16px;
            }

            ul {
                li {
                    list-style: disc;
                    margin-bottom: 15px;

                    @include breakpoint($mxw1000) {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    /* Product Detail Eligibility end*/
}